<template>
    <div>
        <span class="hour">{{hour}}</span>
        <span>时</span>
        <span class="min">{{min}}</span>
        <span>分</span>
        <span class="sec">{{sec}}</span>
        <span>秒</span>
    </div>
</template>
 
<script>
export default {
    name: "countDown",
    props: {
        endDate: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hour: 0,
            min: 0,
            sec: 0
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.countDown()
        // }, 200);
        this.$nextTick(() => {
             this.countDown()
        })
    },
    methods: {
        countDown() {
            let now = Date.parse(new Date())
            var date = new Date(this.endDate);
            // 获取时间戳（毫秒）
            var timestamp = date.getTime();
            if (timestamp + (24 * 60 * 60 * 1000) < now) {
                return console.log(1)
            }
            if (timestamp + (24 * 60 * 60 * 1000) >= now) {
                const msec = timestamp + (24 * 60 * 60 * 1000) - now;
                let hour = parseInt(msec / 1000 / 60 / 60);
                let min = parseInt(msec / 1000 / 60 % 60);
                let sec = parseInt(msec / 1000 % 60);
                this.hour = hour > 9 ? hour : '0' + hour;
                this.min = min > 9 ? min : '0' + min;
                this.sec = sec > 9 ? sec : '0' + sec;
                const that = this;
                setTimeout(function () {
                    that.countDown();
                }, 1000)
            }
        }
    }
}
</script>
 
<style scoped lang="scss">
div {
    display: inline-block;
}
.hour {
    width: pxrem(16px);
    height: pxrem(13px);
    color: #f92b2b;
    font-weight: 700;
    font-size: 16px;
    margin: 0 3px;
}

.min {
    width: pxrem(16px);
    height: pxrem(13px);
    color: #f92b2b;
    font-weight: 700;
    font-size: 16px;
    margin: 0 3px;
}

.sec {
    width: pxrem(16px);
    height: pxrem(13px);
    color: #f92b2b;
    font-weight: 700;
    font-size: 16px;
    margin: 0 3px;
}
</style>