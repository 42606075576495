<template>
    <div class="type_area">
        <!-- <div v-if="false"> -->
        <div v-if="$route.query.orderNumber&&!payFinished">
            <div style="background: #FFFFFF;border-radius: 10px;padding:20px;margin-top:20px;">
                <div style="display:flex;align-items:center;justify-content: space-between;">
                    <div>
                        <img style="width:20px;height:18px;margin-right:10px;vertical-align: middle;" src="../../assets/images/orderInfo/subsuccess.svg" alt="">
                        <span style="font-weight: bold;font-size: 18px;color: #333333;vertical-align: middle;">您的订单已提交成功 ，请尽快完成付款！</span>
                    </div>
                    <div style="color:#F92B2B;display:flex;align-items: center;">
                        <div>剩余支付时间: </div>
                        <countDown v-if="endDate" :endDate="endDate"></countDown>
                    </div>
                </div>
                <div style="margin-top:12px;background: #F7F7F7;border-radius: 2px;padding:15px;font-size: 15px;color: #333333;">
                    <div>订单号： {{payDetail.orderNumber}}</div>
                    <div>发票类型： {{payDetail.invoice}}</div>
                    <div>下单时间： {{payDetail.postDate}}</div>
                </div>
                <div style="margin:12px 0;display:flex;align-items: center;">
                    <div style="width: 6px;height: 16px;background: #1A9DFF;border-radius: 100px;"></div>
                    <div style="font-weight: bold;font-size: 16px;color: #333333;margin-left:6px;">请选择支付方式</div>
                </div>
                <div style="display:flex;align-items: center;margin-top:10px;height:60px;">
                    <div v-for="(item,index) in payDetail.paymentMethod" :key="index" :class="{'selected-pay':currentPay===item.id}" @click="currentPay=item.id" style="margin-right:30px;cursor: pointer;">
                        <div v-if="item.methodName.includes('支付宝')" style="text-align:center;width:70px;">
                            <img src="../../assets/images/orderInfo/alipay.svg" alt="">
                            <div style="text-align:center;">支付宝</div>
                        </div>
                        <div v-if="item.methodName.includes('微信')" style="text-align:center;width:70px;">
                            <img src="../../assets/images/orderInfo/vx.svg" alt="">
                            <div style="text-align:center;">微信</div>
                        </div>
                        <div v-if="item.methodName.includes('对公')" style="text-align:center;width:70px;">
                            <img src="../../assets/images/orderInfo/duigong.svg" alt="">
                            <div style="text-align:center;">对公打款</div>
                        </div>
                        <div v-if="item.methodName.includes('欠款')" style="text-align:center;width:70px;">
                            <img src="../../assets/images/orderInfo/owe.svg" alt="">
                            <div style="text-align:center;">欠款支付</div>
                        </div>

                    </div>
                </div>
            </div>
            <div style="height: 66px;width:1200px;background: #FFFFFF;display:flex;align-items:center;font-size: 14px;color: #333333;justify-content: space-between;margin-top:20px;">

                <div style="font-size: 16px;margin-left:20px;font-weight: bold;">应付金额：<span style="font-size: 22px;color: #F92B2B;font-weight: 700;">￥{{payDetail.paid}}</span></div>

                <div @click="doPay" style="width: 155px;height: 66px;background: #1A9DFF;font-weight: bold;font-size: 22px;color: #FFFFFF;text-align: center;line-height: 66px;cursor: pointer;">立即支付</div>

            </div>
        </div>
        <!-- <div style="background: #FFFFFF;border-radius: 10px;padding:20px;margin-top:20px;" v-if="true"> -->
        <div style="background: #FFFFFF;border-radius: 10px;padding:20px;margin-top:20px;" v-if="transSuccess||$route.query.prepareOrderNumber||$route.query.mainPrepareOrderNumber">
            <div style="margin:50px auto;text-align:center;">
                <div>
                    <img style="width:70px;height:70px;" src="../../assets/images/orderInfo/subsuccess.svg" alt="">
                </div>
                <div style="font-weight: bold;font-size: 20px;color: #333333;margin-top:10px;">提交成功</div>
                <div style="font-size: 14px;color: #A0A0A0;margin-top:20px;">订单已创建成功，请等待后台审核到账情况，如有紧急情况请及时联系客户经理~</div>
                <div style="background: #F8F9FA;border-radius:4px;padding:20px;width: 662px;margin:20px auto;">
                    <div style="display:flex;justify-content: space-between;">
                        <div>订单ID：{{$route.query.mainOrderNumber||$route.query.mainPrepareOrderNumber||payDetail.orderNumber||$route.query.prepareOrderNumber}}</div>
                        <div>创建时间：{{payDetail.postDate}}</div>
                    </div>
                    <Progress :stepInfo="stepInfo" :lastIndex="lastIndex" style="margin-top:20px;" />
                </div>

                <div style="margin-top:50px;">
                    <el-button v-if="roleNumber==2" @click="$router.push('/customer/customerIndex')">返回主页</el-button>
                    <el-button v-else @click="$router.push('/index')">返回首页</el-button>
                    <el-button class="btn" style="background:#1A9DFF;color:#fff;" @click="viewOrder">查看订单</el-button>
                </div>
            </div>
        </div>
        <div style="background: #FFFFFF;border-radius: 10px;padding:20px;margin-top:20px;" v-if="payFinished&&payState">
            <div v-if="payState===1" style="margin:50px auto;width:300px;text-align:center;">
                <div>
                    <img style="width:70px;height:70px;" src="../../assets/images/orderInfo/subsuccess.svg" alt="">
                </div>
                <div style="font-weight: bold;font-size: 20px;color: #333333;margin-top:10px;">支付成功</div>
                <div style="margin-top:50px;">

                    <el-button v-if="roleNumber==2" @click="$router.push('/customer/customerIndex')">返回主页</el-button>
                    <el-button v-else @click="$router.push('/index')">继续采购</el-button>
                    <el-button style="background:#1A9DFF;color:#fff;" class="btn" @click="viewOrder">查看订单</el-button>
                </div>
            </div>
            <div v-if="payState===2" style="margin:50px auto;width:300px;text-align:center;">
                <div>
                    <img style="width:70px;height:70px;" src="../../assets/images/orderInfo/payfail.svg" alt="">
                </div>
                <div style="font-weight: bold;font-size: 20px;color: #333333;margin-top:10px;">支付失败</div>
                <div style="margin-top:50px;">
                    <el-button @click="viewOrder">查看订单</el-button>
                    <el-button style="background:#1A9DFF;color:#fff;" class="btn" @click="continuePay">继续支付</el-button>
                </div>
            </div>
        </div>
        <payDialog v-if="payVisible" :payVisible="payVisible" @finish="payFinish" @retry="payRetry" @closed="closed" :wxPic="wxPic" :aliPic="aliPic" :selectedValue="currentPay"></payDialog>

    </div>
</template>

<script>
import countDown from '@/views/pay/component/countDown'
import payDialog from '@/views/pay/component/payDialog'
import Progress from '@/views/pay/component/progress'
export default {
    components: {
        countDown,
        payDialog,
        Progress
    },
    data() {
        return {
            currentPay: 0,
            payVisible: false,
            payFinished: false,
            payState: null,
            wxPic: '',
            aliPic: '',
            payImg: '',
            endDate: '', //倒计时,
            stepInfo: {
            },
            payDetail: {},
            transSuccess: false,
            roleNumber: localStorage.getItem('roleNumber'),
            lastIndex: 0
        }
    },
    async created() {

        if (this.$route.query.payState === 0) {
            const res = await this.$orderApis.multiplePayResult({
                orderNumber: this.$route.query.orderNumber.split(','),
                mainOrderNumber: this.$route.query.mainOrderNumber
            })
            if (res.data.data.result) {
                this.payFinished = true
                this.payState = 1
            } else {
                this.payFinished = true
                this.payState = 2
            }
        } else {
            this.getPayDetail()
        }

    },
    methods: {
        //查询支付宝支付结果
        // async getAliResult() {
        //     const fd = new FormData()
        //     fd.append('orderNumber', this.$route.query.orderNumber)
        //     fd.append('paymentType', 'aliPay')
        //     const res = await this.$orderApis.getPayResult(fd)
        //     if (res.data.data.result) {
        //         this.payFinished = true
        //         this.payState = 1
        //     }
        // },
        // async getOweResult() {
        //     const fd = new FormData()
        //     fd.append('orderNumber', this.$route.query.orderNumber)
        //     fd.append('paymentType', 'arrears')
        //     const res = await this.$orderApis.getPayResult(fd)
        //     if (res.data.data.result) {
        //         this.payFinished = true
        //         this.payState = 1
        //     }
        // },
        // async getWxResult() {
        //     const fd = new FormData()
        //     fd.append('orderNumber', this.$route.query.orderNumber)
        //     fd.append('paymentType', 'wechat')
        //     const res = await this.$orderApis.getPayResult(fd)
        //     if (res.data.data.result) {
        //         this.payFinished = true
        //         this.payState = 1
        //     }
        // },
        // async getTransResult() {
        //     const fd = new FormData()
        //     fd.append('orderNumber', this.$route.query.orderNumber)
        //     fd.append('paymentType', 'trans')
        //     const res = await this.$orderApis.getPayResult(fd)
        //     if (res.data.data.result) {
        //       this.transSuccess = true
        //              this.payFinished = true

        //     }
        // },
        //支付查询
        async getResultPay() {
            const res = await this.$orderApis.multiplePayResult({
                orderNumber: this.$route.query.orderNumber?this.$route.query.orderNumber.split(','):null || this.$route.query.prepareOrderNumber.split(','),
                mainOrderNumber: this.$route.query.mainOrderNumber|| this.$route.query.mainPrepareOrderNumber
            })
            if (res.data.data.result) {
                this.transSuccess = true
                this.payFinished = true

            }
        },
        async getPayDetail() {
            const res = await this.$orderApis.multiplePay({
                orderNumber: this.$route.query.orderNumber?this.$route.query.orderNumber.split(','):null || this.$route.query.prepareOrderNumber.split(','),
                mainOrderNumber: this.$route.query.mainOrderNumber|| this.$route.query.mainPrepareOrderNumber
            })
           if(res.data.code==200){
            res.data.data.invoice = res.data.data.invoice?res.data.data.invoice.join(','):''
            res.data.data.orderNumber =res.data.data.orderNumber? res.data.data.orderNumber.join(','):''
            this.payDetail = res.data.data
            this.endDate = res.data.data.postDate
            this.stepInfo = res.data.data.orderSteps
            const reverseArr = JSON.parse(JSON.stringify(res.data.data.orderSteps))
            const lastOne = reverseArr.reverse().find(item => item.state);
            this.lastIndex = this.stepInfo.findIndex(item => item.name==lastOne.name) 
           }
            this.getResultPay()
          
            //     console.log(reverseArr,this.lastIndex);
            // if (res.data.data.paymentMethodName == '支付宝支付') {
            //     this.getAliResult()
            // }
            // if (res.data.data.paymentMethodName == '微信支付') {
            //     this.getWxResult()
            // }
            // if (res.data.data.paymentMethodName == '对公打款') {
            //     this.getTransResult()
            // }
            // if (res.data.data.paymentMethodName == '欠款支付') {
            //     this.getOweResult()
            // }
        },

        async doPay() {
            console.log(this.currentPay);
            if (!this.currentPay) return this.$message.error('请选择支付方式');
          if(this.currentPay==6){
            this.payVisible = true;
          }else{
            const res = await this.$orderApis.multipleDoPay({
                orderNumber: this.$route.query.orderNumber.split(','),
                mainOrderNumber: this.$route.query.mainOrderNumber,
                PaymentId: this.currentPay,
                image: this.payImg
            },this.currentPay == 3?'arraybuffer':'')
            console.log(res,'asdasda');
            if (this.currentPay == 2) {
                window.open(res.data)
            } else if (this.currentPay == 3) {
                
                this.wxPic = `data: image/jpeg;base64,${btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`
                console.log(this.wxPic);
                
                this.payVisible = true;
            }else if (this.currentPay == 7) {
              
                if(res.data == 'SUCCESS'){
                    this.payFinished = true
                    this.payState = 1
                }else{
                     this.$toast('您的欠款已到欠款额度，不允许使用欠款支付')
                }
            }
          }
           
            // if (this.currentPay == '欠款支付') {
            //     this.$toast('您的欠款已到欠款额度，不允许使用欠款支付')
            // } else {
            // if (this.currentPay == '支付宝支付') {
            //     const fd = new FormData()
            //     fd.append('orderNumber', this.$route.query.orderNumber)
            //     fd.append('paymentType', 'aliPay')
            //     const res = await this.$orderApis.multipleDoPay(fd)
            //     console.log(res);
            //     window.open(res.data)
            // } else if (this.currentPay == '微信支付') {
            //     const fd = new FormData()
            //     fd.append('orderNumber', this.$route.query.orderNumber)
            //     fd.append('paymentType', 'wechat')
            //     const res = await this.$orderApis.multipleDoPay(fd, 'arraybuffer')
            //     console.log(res);
            //     this.wxPic = `data: image/jpeg;base64,${btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`
            //     this.payVisible = true;
            // }else if (this.currentPay == '欠款支付') {
            //     const fd = new FormData()
            //     fd.append('orderNumber', this.$route.query.orderNumber)
            //     fd.append('paymentType', 'arrears')
            //      const res = await this.$orderApis.multipleDoPay(fd)
            //     console.log(res);
            //     if(res.data == 'SUCCESS'){
            //         this.payFinished = true
            //         this.payState = 1
            //     }else{
            //          this.$toast('您的欠款已到欠款额度，不允许使用欠款支付')
            //     }
            // }
            //  else {
            //     this.payVisible = true;
            // }

            // }

        },
        //支付完成
        async payFinish(val) {
            // const fd = new FormData()
            // fd.append('orderNumber', this.$route.query.orderNumber)
            // fd.append('paymentType', val.type)
            if (val.type == 'trans') {
                const result = await this.$orderApis.multipleDoPay({
                orderNumber: this.$route.query.orderNumber.split(','),
                mainOrderNumber: this.$route.query.mainOrderNumber,
                PaymentId: this.currentPay,
                image: val.image
            })
                console.log(result);
                if (result.data == 'SUCCESS') {
                    this.getPayDetail()
                    this.transSuccess = true
                    this.payFinished = true
                } else {
                    this.$message.warning('订单支付失败或订单已支付')
                }
            } else {
                const res = await this.$orderApis.multiplePayResult({
                orderNumber: this.$route.query.orderNumber.split(','),
                mainOrderNumber: this.$route.query.mainOrderNumber
            })
                if (res.data.data.result) {
                    this.payFinished = true
                    this.payState = 1
                } else {
                    this.payFinished = true
                    this.payState = 2
                }
            }

            this.payVisible = false
        },
        //重新支付
        payRetry() {

            this.payVisible = false
        },
        //关闭弹窗
        closed() {
            this.payVisible = false
        },
        viewOrder() {
            this.$router.push({ path: '/orderPersDetail', query: { orderNumber: this.$route.query.orderNumber,mainOrderNumber:this.$route.query.mainOrderNumber,prepareOrderNumber:this.$route.query.prepareOrderNumber,mainPrepareOrderNumber:this.$route.query.mainPrepareOrderNumber } })
        },
        continuePay() {
            this.payFinished = false
            this.payState = null
        }
    }
}
</script>

<style lang="scss" scoped>
.selected {
    background: #f5fbff;
}
.selected-pay {
    // border-radius: 6px;
    // border: 2px solid #f7a400;
    // width: 42px;
    // height: 42px;
    img {
        width: 50px;
        height: 50px;
    }
}
.btn {
    &:hover {
        background: #0687fe !important;
    }
}
.process_more {
    .set-2 {
        display: flex;
        justify-content: space-between;
    }
    .set-3 {
        .content_box {
            display: flex;
            align-items: center;
        }
        .left_img {
            display: inline-block;
            width: 35px;
            height: 17px;

            background-size: 100% 100%;
        }
        .height {
            height: 100%;
            background: #1990ff;
            width: 1px;
            margin-left: 25%;
        }
    }
    .content {
        width: 100%;
        //   margin-top: -3px;
        margin-bottom: 20rpx;
        //   display: flex;
        justify-content: space-between;
        padding-left: 10px;
        .description {
            font-weight: 600;
            color: black;
        }
        .time {
            color: #9fa9ba;
        }
    }
}
</style>